jQuery( document ).ready(function($) {

  $("#tate-mcrae-fan-qa-us-rca-45press").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    console.log(data)
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('.step').removeClass('active')
        $('.step-thankyou').addClass('active')
      },
      error: function (err) {
        $('.fan-required').addClass('error')
        $('span.error').slideToggle()
      }
    });
  });

});